import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const feedback = () => (
  <Layout>
    <SEO
      title="Feedback | We Read Every Word"
      description="We care a lot about making Code Championship the best it can possibly be. How can we make Code Championship even better?"
      keywords={['feedback', 'opinion']}
    />
    <h1>Feedback</h1>
    <div className="stripe-hr thin" />
    <p>Feedback is always greatly appreciated. We read every piece of feedback.</p>
    <b>feedback@codechampionship.com</b>
  </Layout>
);

export default feedback;
